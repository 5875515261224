body,
html {
  margin: 0;
  font-family: 'Twemoji Country Flags', 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
    font-weight: revert;
  }
}

.ql-container {
  font-family: system-ui, -apple-system !important;
  line-height: 2 !important;
}

.custom-quill .ql-editor {
  line-height: 1.8 !important; /* Adjust the line height as per your requirement */
  font-size: medium;
}

/* Your CSS file or inline styles */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Style the "Previous" and "Next" buttons */
.previous,
.next {
  padding: 6px;
  margin: 5px;
  background-color: rgb(229 231 235);
  color: rgb(41 37 36);
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

/* Style the page number buttons */
.notActivePage {
  padding: 6px;
  margin: 5px;
  background-color: transparent;
  color: rgb(41 37 36);
  border: 2px solid #000;
  border-radius: 5px;
  font-size: 12px;
}

.disabled {
  padding: 6px;
  margin: 5px;
  background-color: rgb(229 231 235);
  color: rgb(41 37 36);
  border-radius: 5px;
  font-size: 12px;
  opacity: 0.4;
}

/* Style the active page button */
.activePage {
  /* padding: 6px;
  margin: 5px;
  background-color: rgb(41 37 36);
  color: #fff;
  border: 2px solid rgb(41 37 36);
  border-radius: 5px;
  cursor: pointer; */
  display: none;
}

.breakLink {
  padding: 6px;
  margin: 5px;
  background-color: transparent;
  color: rgb(41 37 36);
  border: 2px solid rgb(41 37 36);
  border-radius: 5px;
  cursor: pointer;
}

.custom-toast {
  color: black; /* Set the text color to black */
  /* Add any additional styles you want */
}

@media (max-width: 767px) {
  .previous,
  .next {
    padding: 6px;
  }
  .notActivePage,
  .activePage,
  .breakLink {
    padding: 3px; /* Smaller padding for mobile devices */
  }
}

.filepond--drop-label {
  background-color: rgb(243 244 246) !important;
  border-radius: 10px;
  font-size: 12px !important;
}

*:focus {
  --tw-ring-color: none !important;
}

.react-tabs__tab {
  font-size: 12px; /* Adjust the font size as needed */
}

.react-tabs__tab-list {
  border-color: rgb(229 231 235) !important;
}

.react-tabs__tab--selected {
  border-color: rgb(229 231 235) !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
