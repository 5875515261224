.samp-bg {
  background-color: #ffffff;
  animation-name: colorChange;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes colorChange {
  /* from {
    background-color: #d4ffde;
  }
  to {
    background-color: #e0f0ff;
  } */
  0% {
    background-color: #ffffff;
  }
  25.0% {
    background-color: #d4ffde;
  }
  50.0% {
    background-color: #f18ebf;
  }
  100.0% {
    background-color: #e0f0ff;
  }
}

.samp-item-bg {
  background-color: #fddafb;
  animation-name: colorChangeTwo;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes colorChangeTwo {
  0% {
    background-color: #fddafb;
  }
  25.0% {
    background-color: #f6e6ce;
  }
  100.0% {
    background-color: #ffffff;
  }
}
